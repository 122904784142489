// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group accordion
////

/// Default background color of an accordion group.
/// @type Color
$accordion-background: $white !default;

/// If `true`, adds plus and minus icons to the side of each accordion title.
/// @type Boolean
$accordion-plusminus: true !default;

/// Default text color for items in a Menu.
/// @type Color
$accordion-item-color: foreground($accordion-background, $black) !default;

/// Default background color on hover for items in a Menu.
/// @type Color
$accordion-item-background-hover: $light-gray !default;

/// Default padding of an accordion item.
/// @type Number | List
$accordion-item-padding: 0.1rem 0.1rem !default;

/// Default background color of tab content.
/// @type Color
$accordion-content-background: $white !default;

/// Default border color of tab content.
/// @type Color
$accordion-content-border: 1px solid $light-gray !default;

/// Default text color of tab content.
/// @type Color
//$accordion-content-color: foreground($accordion-content-background, $body-font-color) !default;
$accordion-content-color: foreground($accordion-content-background, $black) !default;

/// Default padding for tab content.
/// @type Number | List
$accordion-content-padding: 0;

/// Adds styles for an accordion container. Apply this to the same element that gets `data-accordion`.
@mixin accordion-container {
  list-style-type: none;
  background: $accordion-background;
  margin-#{$global-left}: 0;
}

/// Adds styles for the accordion item. Apply this to the list item within an accordion ul.
@mixin accordion-item {
  &:first-child > :first-child {
    border-radius: $global-radius $global-radius 0 0;
  }

  &:last-child > :last-child {
    border-radius: 0 0 $global-radius $global-radius;
  }
}

/// Adds styles for the title of an accordion item. Apply this to the link within an accordion item.
@mixin accordion-title {
  display: block;
  padding: $accordion-item-padding;
  line-height: 1;
  font-size: rem-calc(15);
  //color: $accordion-item-color;
  //color: $theme-color;

  color: $black;
  position: relative;
  border-bottom: $accordion-content-border;
  //border-bottom: 0;

  :last-child:not(.is-active) > & {
    border-radius: 0 0 $global-radius $global-radius;
    border-bottom: $accordion-content-border;
  }

  &:hover {
    color: $theme-light-color;
    //background-color: $accordion-item-background-hover;
  }
  &:focus {
    color: $theme-color;
    color: $black;
    //color: $theme-color;
    //background-color: $accordion-item-background-hover;
  }

  @if $accordion-plusminus {
    &::before {
      content: '';
      position: absolute;
      #{$global-right}: 1rem;
      top: 50%;
      margin-top: -0.5rem;
      width: 7px;
      height: 7px;
      margin: -4px 0 0 0;
      border: none;
      border-right: solid 2px $light-gray;
      border-bottom: solid 2px $light-gray;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }

    .is-active > &::before {
      content: '';
      position: absolute;
      #{$global-right}: 1rem;
      top: 50%;
      margin-top: -0.5rem;
      width: 7px;
      height: 7px;
      margin: -4px 0 0 0;
      border: none;
      border-top: solid 2px $light-gray;
      border-left: solid 2px $light-gray;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);

    }
  }
}

/// Adds styles for accordion content. Apply this to the content pane below an accordion item's title.
@mixin accordion-content {
  padding: $accordion-content-padding;
  display: none;
  border-bottom: $accordion-content-border;
  border-bottom: 0;
  background-color: $accordion-content-background;
  color: $black;
  //color: $accordion-content-color;

  :last-child > &:last-child {
    border-bottom: $accordion-content-border;
  }
  ul {
    padding:0;
    margin:0;
  }
  li {
    margin:0;
    padding:0.15rem;
    list-style: none;
    list-style-position: inside;
    marker-offset: 0;
    border-bottom: 1px solid rgba(gray, 0.25);
    &:last-child {
      //border: none;
    }

    &:hover {
        color: $black;
        //background-color: $light-gray;
        //background-color: $accordion-item-background-hover;
    }
   
    a { 
        color: $black;
        &:hover {
            color: $theme-color;
            //background-color: $light-gray;
            //background-color: $accordion-item-background-hover;
       }
    }
  }


}

@mixin foundation-accordion {
  .accordion {
    @include accordion-container;
  }

  .accordion-item {
    @include accordion-item;
  }

  .accordion-title {
    @include accordion-title;
  }

  .accordion-content {
    @include accordion-content;
  }
}
