@mixin global-link {
    a {
        font-weight: bold;
        font-size: 90%;
        color: rgba($black, 0.95); 
        //color: $black; 
        &:hover {
            color: rgba($black, 0.75); 
        }
        &:focus {
            color: $black;
       }
    }
}

.g-header {
    //box-shadow: 0 0 10px rgba($black, 0.5);
    z-index:999;
    
}

.go-top-button {
    z-index: 999;
    position: fixed;
    bottom: 20px;
    right: 20px;
    a {
        display: block;
        z-index: 999;
        background-color: $theme-color;
        &:hover {
            background-color: $theme-light-color;
        }
    }
}

.g-footer {
    width: 100%;
    background-color: $footer-color;
    font-size: 87%; 
    //padding: 1rem;
    @include breakpoint(small) {
        padding: 0 0 1rem;
    }
    @include breakpoint(medium) {
        padding: 0 0 2rem;
    }
    @include breakpoint(large) {
        padding: 0 0 3rem;
    }
    .footer-container {
        margin:0 auto;
        max-width: 1280px;
    }
    .footer-logo {
        padding: 1.5rem 1.5rem 1rem 1.5rem;
    }
    .footer-menu {
        padding: 1rem;
        li {
            padding: 0.25rem;
            @include global-link();
            a {
                padding-left:0;
            }

            &:before {
                content: "- ";
            }
        }
        .address {
            margin-top:0;
            padding-top: 0.6rem;
            h6 {
                font-weight: bold;
            }
            @include global-link();
            a {
                font-weight: normal;
            }
            .tel-info {
                font-size: 60%;
            }
        }
    }
    .social {
        padding: 0 1.5rem;
        li {
            @include global-link();
            a {
                font-size: 24px;
                padding: 0 0.5rem 0 0;
            }
        }
    }
    .copyright {
        img {
            padding: 0.2rem;
        }
        margin: 2rem 1.5rem 1rem;
    }
}

.g-title-header {
    margin: 1rem 0 1rem 0;
    
}

.g-border {
    border-bottom: 5px solid rgba(gray, 0.25);
}

.g-text-border {
    position: relative;
    display: inline-block;
    &:before {
        position: absolute;
        top: 1.2rem;
        left: 0;
        content: "";
        display: inline-block;
        width: 0;
        height: 0.15rem;
        //background: $theme-color;
        background: $primary;
        transition: 1s;
    }
    &:hover:before {
        width: 100%;
    }
}

.g-top-bar {
    z-index: 99;
    padding: 1rem 0;
    .top-bar-site-logo {
        margin-top: 0.1rem;
        @include breakpoint(small) {
            width: 79%;
            max-width: 200px;
        }
        @include breakpoint(medium) {
            width: 200px;
        }
        @include breakpoint(large) {
            width: 200px;
        }
    }
    .top-bar-site-name {
        margin-left: 10px;
        width: 200px;
        color: $white;
        font-weight: bold;
        padding-bottom:10px;
    }
    .top-bar-nav-ico {
        margin: 5px 10px 5px 10px;
        width: 30px;
        .menu-icon {
            @include hamburger($color: $titlebar-icon-color, $color-hover: $titlebar-icon-color-hover, $width: $menu-icon-width, $height: 22px, $weight: 3px );
        }

    }
    .top-bar-nav-name {
        margin: 10px 10px 0 5px;
        font-weight: bold;
        font-size: 78%;
        //max-width: 20px;
        color: $white;
    }
    .top-bar-caption {
        margin: 11px 10px 0 5px;
        letter-spacing: 2px;
        //max-width: 20px;
        font-size: 80%;
        font-weight: 500;
        //color: $white;
        color: $black;
        a {
            color: $white;
            &:hover {
                color: rgba($white, 0.75); 
            }
            &:focus {
                color: $white;
            }
        }
    }

    .search-icon {
        font-size: 24px;
        color: $white;
        margin: 0.45rem 0.6rem;
        label {
            white-space: nowrap;
            display: inline-block;
        }
    
    }
    address {
        margin-right: 1rem;
        color: $white;
        font-size: 80%;
        p {
            margin:0;
        }
        a {
        color: $white;
        
        }
    
    }
}
.heading {
    position: absolute;
    top: 10px;
    right: 20px;
    color: $white;
    font-size: 10px;
    z-index: 3;
}

.g-nav {
    position: fixed;
    height: 100%;
    min-height: 100%;
    width: 270px;
    z-index: $reveal-zindex;
    background: $white;
    transform: translateX(-100%);
    transition: transform ease .25s;
    &.is-active {
        box-shadow: 0 0 10px rgba($black, 0.5);
        transform: translateX(0);
    }
    .g-nav-close-button {
        height: 50px;
    }

    .nav-container {
        height: 80% !important;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -webkit-transform: translateZ(0px);
        .menu {
            min-height: 100%;
            height: 100%;
        }
    
    }
    a {
        font-weight: bold;
        font-size: 90%;
        //color: $black;
        color: rgba($black, 0.9);
        //color: $theme-color;
        &:hover {
            //background: $theme-color;
            //color: $white;
            color: rgba($black, 0.7); 
        }
        &:focus {
            //background: $theme-color;
            color: rgba($black, 0.7); 
            //color: $white;
        }

    }
    li {
        border-top: 1px solid rgba(gray, 0.15);
        padding: 0.5rem;
        &:last-child {
            border-bottom: 1px solid rgba(gray, 0.15);
        }
    }
}

.address {
    background-color: $natural;
    padding: 1rem;
    margin: 1rem 0;
    .tel {
        a {
            //font-size: 90%;
            color: $black;
            //color: $theme-color;
            &:hover {
                //background: $theme-color;
                //color: $white;
                color: rgba($black, 0.75); 
            }
            &:focus {
                //background: $theme-color;
                color: rgba($black, 0.75); 
                //color: $white;
            }
        }
    }
}
.g-side-nav {
    .side-nav-controller {
        background: $theme-light-color;
        //background: scale-color($theme-color, $lightness: 10%);
        color: $white;
        padding: 1rem;
        font-size: 24px;
        height: 69px;
    }
    .side-nav-header {
        margin: 1rem 0;
        h3 { 
            color: $white;
            margin:0;
            font-weight: 600;
        }
        padding: 1rem;
        background: $theme-color;

    
    }
    .side-nav-contents {
        padding: 0 1rem; 
    }        
    .tag-search-sub-header {
        padding: 0 0.5rem 0.5rem;
        border-bottom: 3px solid rgba(gray, 0.25);
        margin: 1rem 0.5rem 0.5rem;
        h5 {
            margin:0;
            font-weight: bold;
        }
    }

    .pickup-tags {
        margin: 0.3rem;
        padding: 0.3rem;
        float: left;
        @include global-link();
        .label {
            padding: 0.8rem;
        }
    }
}

.about-icon-modal {
    h1 {
        font-weight: bold;
    }
    font-size: 70%;
    
    .icon-help-badge {
        color: $white;
    }
    .icon-help-title {
        color: $white;
        font-weight: bold;
    }

    table {
        tbody {
            border: 0;
            img {
                max-width: auto;
                max-width: initial;
                width: 16px !important;
                height: 16px !important;
            }
        }
    }
}

.off-canvas-wrapper {
    position: relative;
    height:auto !important;
    height: 100%;
    min-height: 100%;

}
.main-container-wrap {
    //width: 100%;
    @include breakpoint(small) {
        margin: 0 0 1rem 0;
    }
    @include breakpoint(medium) {
        margin: 0 2rem 2rem 2rem;
    }
    @include breakpoint(large) {
        margin: 0 3rem 3rem 3rem;
    }
}
.main-container {
    .small { font-size: $icon-size-small; }
    .medium { font-size: $icon-size-medium; }
    .large { font-size: $icon-size-large; }
    .font-strong { font-weight: bold; }
    .border-bottom-dotted {
        padding-bottom: 0.2rem;
        border-bottom: dotted 1px;
    }
    .text-color-primary { color: $primary; }
    .text-color-alert { color: $alert-color; }
    .text-color-warning { color: #FF8000; }
    .text-color-gray { color: gray; }
    .label-float-left {
        label { display: block; float: left; margin-right: 7px;}
    }
    //.text-color-warning { color: $warning-color; }
    //a {
    //    color: $black;
    //    &:hover, &:focus {
    //        color: rgba($black, 0.75);
    //    }
    //}
    
    max-width: 1280px;
    @include breakpoint(small) {
        min-height: 100%;
        height: auto !important;
        height: 100%;
        //margin: 0 0 1rem 0;
        margin: 0 auto;
        font-size: 87%;
    }
    @include breakpoint(medium) {
        min-height: 100%;
        height: auto !important;
        height: 100%;
        //margin: 0 2rem 2rem 2rem;
        margin: 0 auto;
        font-size: 87%;
    }
    @include breakpoint(large) {
        min-height: 100%;
        height: auto !important;
        height: 100%;
        //margin: 0 3rem 3rem 3rem;
        //margin: 0 auto;
        margin: 0 auto;
        font-size: 87%;
        //padding-bottom: 400px;
    }
    

    .top-site-caption {
        margin:  0rem 0 0.5rem;
        @include breakpoint(small) {
            margin: 0;
        }
        @include breakpoint(large) {
            margin:  0rem 0 0.5rem;
        }
    

    
    }
    .main-visual {
        margin: 1rem 0;
    }
    .orbit {
        @include foundation-orbit();
        .orbit-container {
            height: auto !important;
            //max-height: 400px !important;
            outline: 0;
        }
        img.main-visual-aside {
            width: 50%;
            max-width: 50%;
        }
        .main-visual-aside-caption {
            width: 50%;
        }
        img.main-visual-bside {
            width: 50%;
            max-width: 50%;
        }
        .main-visual-bside-caption{
            width: 50%;
            padding-left: 1rem;
        }
        .clearfix {
            margin:0;
            padding:0;
        }
        @include breakpoint(small) {
            .orbit-bullets {
                margin-bottom: 0;
            }
        }
        @include breakpoint(medium) {
            .orbit-bullets {
                margin-bottom: 0.5rem;
            }
        }

    }
    .top-jobs-search {
        background: $natural;
        .top-job-search-header {
            //:background: $theme-color;
            background: scale-color($theme-color, $lightness: 4%);
            color: $white;
            //margin-bottom: 1.5rem;
            margin: 0rem 0 1.5rem;
            padding: 0.1rem 1rem;
            h1 {
                margin:0;
                padding:0;
                line-height: 2.5;
            }
        }

        .top-job-search-panel {
            padding: 0 1rem;
            p {
                padding: 0;
                margin:0 0 0.4rem 0;
            }
        }
    }
    .help-menu {
        margin: 1rem 0;
        @include global-link();
        li {
            &:first {
                border: 0;
            }
            border-right: 1px solid rgba(gray, 0.15);
        }
    
    }
    .top-jobs {
        @include breakpoint(small) {
            margin: 1rem 0rem;
        }
        @include breakpoint(medium) {
            margin: 2rem 1rem;
        }
        @include breakpoint(large) {
            margin: 2rem 1rem;
            border: 3px solid rgba(gray, 0.15);
            //border-bottom: 3px solid rgba(gray, 0.15);
            
        }

        padding: 1rem 0;
        background: $natural;
        .jobs-button {
            @include breakpoint(small) {
                margin: 0rem 1rem;
                text-align: center;
            }
            @include breakpoint(medium) {
                margin:0rem  2rem;
                text-align: right;
            }
            @include breakpoint(large) {
                margin:0rem  3rem;
                text-align: right;
            }
        }
        .top-jobs-header-closeup {
            @include breakpoint(small) {
                margin: 1rem 2rem;
            }
            @include breakpoint(medium) {
                margin:1rem  3rem;
            }
            @include breakpoint(large) {
                margin:1rem  4rem;
            }


            span.title {
                font-weight: bold;
                background: $theme-color;
                padding: 0.5rem 1.5rem;
                color: $white;

                //background: $primary-color;
                //padding: 0.5rem 1.5rem;
                //color: $white;
            }  
            span.comment {
                font-weight: bold;
                background: $alert-color;
                padding: 0.5rem 1.5rem;
                margin-left: 1rem; 
                color: $white;
                position:relative;
                font-size: 100%;
                @include breakpoint(small) {
                    font-size: 50%;
                }
                @include breakpoint(medium) {
                    font-size: 70%;
                    padding: 0.5rem 0.5rem;
                    &:after {
                        border:10px solid transparent;
                        border-right-color: $alert-color;
                        border-left-width:0;
                        left:-8px;
                        content:"";
                        display:block;
                        top:6px;
                        position:absolute;
                        width:0;
                    }

                }
                @include breakpoint(large) {
                    font-size: 100%;
                    padding: 0.5rem 1.5rem;
                    &:after {
                        border:10px solid transparent;
                        border-right-color: $alert-color;
                        border-left-width:0;
                        left:-8px;
                        content:"";
                        display:block;
                        top:10px;
                        position:absolute;
                        width:0;
                    }
                }
            }
 
        }
        .top-jobs-header-pickup {
            @include breakpoint(small) {
                margin: 1rem 2rem;
            }
            @include breakpoint(medium) {
                margin:1rem  3rem;
            }
            @include breakpoint(large) {
                margin:1rem  4rem;
            }



            span.title {
                font-weight: bold;
                background: $theme-color;
                padding: 0.5rem 1.5rem;
                color: $white;
                white-space: nowrap;

                //background: $primary-color;
                //padding: 0.5rem 1.5rem;
                //color: $white;
                @include breakpoint(small) {
                    font-size: 70%;
                }
                @include breakpoint(medium) {
                    font-size: 94%;
                }
                @include breakpoint(large) {
                    font-size: 100%;
                }



            }  
            span.comment {
                background: $warning-color;
                padding: 0.5rem 1.5rem;
                margin-left: 1rem; 
                color: $white;
                position:relative;
                font-size: 100%;
                @include breakpoint(small) {
                    font-size: 50%;
                }
                @include breakpoint(medium) {
                    font-size: 60%;
                    font-weight: bold;
                    padding: 0.5rem 0.5rem;
                    &:after {
                        border:10px solid transparent;
                        border-right-color: $warning-color;
                        border-left-width:0;
                        left:-8px;
                        content:"";
                        display:block;
                        top:5px;
                        position:absolute;
                        width:0;
                    }

                }
                @include breakpoint(large) {
                    font-size: 100%;
                    padding: 0.5rem 1.5rem;
                    &:after {
                        border:10px solid transparent;
                        border-right-color: $warning-color;
                        border-left-width:0;
                        left:-8px;
                        content:"";
                        display:block;
                        top:10px;
                        position:absolute;
                        width:0;
                    }
                }
            }

        }
        .top-jobs-header-new {
            @include breakpoint(small) {
                margin: 1rem 2rem;
            }
            @include breakpoint(medium) {
                margin:1rem  3rem;
            }
            @include breakpoint(large) {
                margin:1rem  4rem;
            }


            span.title {
                font-weight: bold;
                background: $theme-color;
                padding: 0.5rem 1.5rem;
                color: $white;


            }  
            span.comment {
                background: $alert-color;
                padding: 0.5rem 1.5rem;
                margin-left: 1rem; 
                color: $white;
                position:relative;
                font-size: 100%;
                font-weight: bold;
                @include breakpoint(small) {
                    font-size: 70%;
                    padding: 0.5rem 0.5rem;
                    &:after {
                        border:10px solid transparent;
                        border-right-color: $alert-color;
                        border-left-width:0;
                        left:-8px;
                        content:"";
                        display:block;
                        top:6px;
                        position:absolute;
                        width:0;
                    }
                }
                @include breakpoint(medium) {
                    font-size: 70%;
                    padding: 0.5rem 0.5rem;
                    &:after {
                        border:10px solid transparent;
                        border-right-color: $alert-color;
                        border-left-width:0;
                        left:-8px;
                        content:"";
                        display:block;
                        top:6px;
                        position:absolute;
                        width:0;
                    }

                }
                @include breakpoint(large) {
                    font-size: 100%;

                    &:after {
                        border:10px solid transparent;
                        border-right-color: $alert-color;
                        border-left-width:0;
                        left:-8px;
                        content:"";
                        display:block;
                        top:10px;
                        position:absolute;
                        width:0;
                    }
                }
            }

        }

        .top-jobs-closeup-container {
            @include breakpoint(small) {
                margin: 0 0.25rem;
            }
            @include breakpoint(medium) {
                margin: 0 1rem;
            }
            @include breakpoint(large) {
                margin: 0 2rem;
            }
           
            padding: 1rem 0;
            .top-jobs-item {
                cursor: pointer;
                &:hover {
                    border:3px $theme-color solid;
                }
                @include breakpoint(small) {
                    margin: 0 0 1rem;
                    min-width: auto;
                    min-width: initial;
                }
                @include breakpoint(medium) {
                    margin: 0;
                    min-width: 160px;
                }
                @include breakpoint(large) {
                    min-width: 260px;
                    margin: 0;
                }

                background: $white;
                padding: 0;
                border: 3px solid $white;
                .item-header {
                    @include breakpoint(small) {
                        min-height: 30px;
                        max-height: 100%;
                    }
                    @include breakpoint(medium) {
                        min-height: 40px;
                        max-height: 40px !important;
                    }
                    @include breakpoint(large) {
                        min-height: 25px;
                        max-height: 25px !important;
                    }

                    padding: 0.5rem 0.5rem 0.1rem;
                    h6 {
                        padding: 0;
                        margin: 0;
                    }
                }
                .item-update {
                    padding: 0.25rem 0.5rem;
                }
                .item-info {
                    @include breakpoint(small) {
                        padding: 0 0.5rem 0.5rem;
                        font-size: 80%;
                        p {
                            margin: 0;
                        }
                        min-height: 60px;
                        max-height: 100%;
                    }
                    @include breakpoint(medium) {
                        padding: 0 0.5rem 0.5rem;
                        font-size: 80%;
                        min-height: 100px;
                        max-height: 100px !important;
                    }
                    @include breakpoint(large) {
                        padding: 0 0.5rem 0.5rem;
                        font-size: 100%;
                        min-height: 100px;
                        max-height: 100px !important;
                    }
                }
            }
        }

        .top-jobs-pickup-container {
            @include breakpoint(small) {
                margin: 0 0.25rem;
            }
            @include breakpoint(medium) {
                margin: 0 1rem;
            }
            @include breakpoint(large) {
                margin: 0 2rem;
            }
 
            padding: 1rem 0;
            .top-jobs-item {
                cursor: pointer;
                margin: 0 0 1rem;
                background: $white;
                padding: 0;
                border: 3px solid $white;
                min-width: 130px;
                &:hover {
                    border:3px $theme-color solid;
                }


                .item-header {
                    @include breakpoint(small) {
                        min-height: 40px;
                        max-height: 40px !important;
                    }
                    @include breakpoint(medium) {
                        min-height: 40px;
                        max-height: 40px !important;
                    }
                    @include breakpoint(large) {
                        min-height: 40px;
                        max-height: 40px !important;
                    }

                    padding: 0.5rem 0.5rem 0.1rem;
                    h6 {
                        padding: 0;
                        margin: 0;
                        font-size: 80%;
                    }
                }
                .item-update {
                    padding: 0.25rem 0.5rem;
                }
                .item-info {
                    @include breakpoint(small) {
                        padding: 0 0.5rem 0.25rem;
                        min-height: 120px;
                        max-height: 120px !important;
                        font-size: 80%;
                    }
                    @include breakpoint(medium) {
                        padding: 0 0.5rem 0.5rem;
                        min-height: 100px;
                        max-height: 100px !important;
                        font-size: 80%;
 
                    }
                    @include breakpoint(large) {
                        padding: 0 0.5rem 0.5rem;
                        min-height: 100px;
                        max-height: 100px !important;
                        font-size: 80%;
                        //overflow-y: hidden;
                        //text-overflow: ellipsis;
                        .large2 {
                            min-width: 150px;
                        
                        }
                    }
                }
            }
        }
        
        .top-jobs-new-container {
            @include breakpoint(small) {
                margin: 0 0.25rem;
            }
            @include breakpoint(medium) {
                margin: 0 1rem;
            }
            @include breakpoint(large) {
                margin: 0 2rem;
            }
 
            padding: 1rem 0;
            .top-jobs-item {
                cursor: pointer;
                margin: 0 0 1rem;
                background: $white;
                padding: 0;
                border: 3px solid $white;
                min-width: 135px;
                &:hover {
                    border:3px $theme-color solid;
                }


                .item-header {
                    @include breakpoint(small) {
                        min-height: 45px;
                        max-height: 45px !important;
                    }
                    @include breakpoint(medium) {
                        min-height: 50px;
                        max-height: 50px !important;
                    }
                    @include breakpoint(large) {
                        min-height: 50px;
                        max-height: 50px !important;
                    }

                    padding: 0.5rem 0.5rem 0.1rem;
                    h6 {
                        padding: 0;
                        margin: 0;
                        font-size: 80%;
                    }
                }
                .item-update {
                    padding: 0.15rem 0.5rem;
                }
                .item-info {
                    padding: 0 0.5rem 0.25rem;
                    min-height: 30px;
                    max-height: 30px !important;
                    font-size: 80%;
                }
            }
        }
    }

    .notes {
        font-size: 90%;
        padding: 1rem 1rem;
    }
    .tables {
        padding: 15px 0;
    }
    .question {
        a {
            font-size: 140%;
            border-bottom: dotted 1px;
        }
    }
    
    .top-nav-breadcrumbs {
        @include foundation-breadcrumbs();
        margin-top:3px;
    }

    .jobs {
         //margin: 1rem;

        .jobs-wrap {
            cursor: pointer;
            //background: rgba($natural, 0.25);
            margin: 1rem 0;
            padding: 0.5rem 0 0;
            border: 5px solid rgba(gray, 0.25);
            //border: 5px solid rgba($theme-color, 0.6);
            border-radius: $global-radius;
            &:hover {
                border: 5px solid $theme-color;
            }
        }
        .jobs-header {
            background: $theme-color;
            color: $white;
            //background: scale-color($black, $lightness: 93%);
            //border-bottom: 1px solid rgba(gray, 0.25);
            //border-left: 6px solid rgba(gray, 0.25);
            padding: 0.1rem;
            margin:0;
            h2, h3 {
                margin:0;
            } 

            p {
                margin:0;
                padding:0;
                float: right;
            }
        }
        .jobs-thumnail {
            //background: $white;
            border: 3px solid $white;
            margin: 0 0 0rem 0;
        }
        .jobs-comment {
            @include breakpoint(small) {
                font-size: 16px;
                font-weight: 600;
            }
            @include breakpoint(medium) {
                font-size: 21px;
                font-weight: 500;
            }
            @include breakpoint(large) {
                font-size: 21px;
                font-weight: 600;
            }

        }

        .jobs-name {
            @include breakpoint(small) {
                font-size: 13px;
            }
            @include breakpoint(medium) {
                font-size: 14px;
            }
            @include breakpoint(large) {
                font-size: 15px;
            }
        }
        .jobs-detail {
            //text-align: center;
            //background: $natural;

        }

        .jobs-contents {
            @include breakpoint(small) {
                padding: 0.5rem 0.5rem;
            }
            @include breakpoint(medium) {
                padding: 1rem 1rem;
            }
            @include breakpoint(large) {
                padding: 1rem 1rem;
            }

            .comment {
                margin-top: 0.5rem;
                color: $primary;
            }
            .read {
                margin-top: 0.5rem;
                //font-size: 110%;
            }
            .jobs-button {
                text-align: right;
                //margin: 0 3.25rem;
                margin: 0;
            }

        }
        .jobs-update-at {
            color: gray;
            //border-bottom: 5px solid rgba(gray, 0.25);
            margin: 0 0.5rem 1rem;
            @include breakpoint(small) {
                font-size: 80%;
            }
            @include breakpoint(medium) {
                font-size: 87%;
            }
            @include breakpoint(large) {
                font-size: 87%;
            }
        }

        .jobs-footer {
            background: $natural;
            border-top: 5px solid rgba(gray, 0.25);
            clear: both;
            @include breakpoint(small) {
                font-size: 80%;
            }
            @include breakpoint(medium) {
                font-size: 86%;
            }
            @include breakpoint(large) {
                font-size: 90%;
            }

            .columns {
                padding:0;
            }
            .jobs-label {
                float: left;
                padding: 0.5rem;
                width: 33.33333%;
                text-align: center;
                border: solid 1px $white;
                white-space: nowrap;
            }
            .jobs-label-border {
                border-top: solid 1px $white;
                border-bottom: solid 1px $white;
                display: block;
                clear: both;
            }

            .jobs-label-short {
                float: left;
                padding: 0.5rem;
                width: 25%;
                text-align: center;
                border-left: solid 1px $white;
                border-right: solid 1px $white;
            }

            .jobs-label-medium {
                float: left;
                padding: 0.5rem;
                width: 50%;
                text-align: center;
                border-left: solid 1px $white;
                border-right: solid 1px $white;
            }
            .jobs-label-long {
                float: left;
                padding: 0.5rem;
                width: 100%;
                text-align: center;
                border-left: solid 1px $white;
                border-right: solid 1px $white;
            }
        }
      
        .jobs-contents-closeup {
            margin: 0 0 1rem 0;
            padding: 1rem 1rem 0 1rem;
            .comment {
                margin-top: 0.5rem;
                //color: $primary;
            }
            .shop-name-container {
                padding: 0.5rem 0rem;
                min-height: 60px;
                height: 60px;
            }

            .read {
                margin-top: 0.5rem;
                //font-size: 110%;
            }
            .thumbnail {
                border: 0;
                border-raduis:0;
                margin-bottom: 0.2rem;    
            }

        }
        .jobs-contents-pickup {
            font-size: 80%;
            //margin: 0 0 1rem 0;
            //padding: 0.2rem;
            .comment {
                margin-top: 0.2rem;
                //color: $primary;
            }
            .shop-name-container {
                //padding: 0 0.2rem;
                border-bottom: 1px dotted rgba(gray, 0.25);
                height: 50px;
            }
            .read {
                //margin-top: 0.5rem;
                font-size: 87%;
            }
            .thumbnail {
                border: 0;
                border-raduis:0;
                margin-bottom: 0.5rem;    
            }
        }

        li {
            list-style:none;
        }
    }
    
    .job {
        
        .job-header {
            //background: scale-color(gray, $lightness: 94%);
            background-color: $theme-color;
            border: 1px solid rgba(gray, 0.25);
            border-radius: $global-radius;
            //background-color: #777;
            color: $white;
            padding: 1rem;
            @include breakpoint(small) {
                margin:0 0 0.5rem 0;
            }
            @include breakpoint(medium) {
                margin:0.5rem 0 1.5rem 0;
            }
            @include breakpoint(large) {
                margin:0.5rem 0 1.5rem 0;
            }

            h1 {
                margin:0;
                font-weight: 700;
            }

        }
        .company-logo {
            vertical-align: middle;
            text-align: center;
            padding: 1rem 0;
        }
        .photo {
            max-height: 400px;
        }
        .job-pr {
            padding: 0 0 1rem 0;
            max-height: 400px;
            border-bottom: 5px solid rgba(gray, 0.25);
            @include breakpoint(small) {
                margin: 0.5rem 0 0 0;
            }
            @include breakpoint(medium) {
                margin:0;
            }
            @include breakpoint(large) {
                margin:0;
            }
        }
        .thumbnails {
            padding: 1.5rem 0;
            text-align: center;
            //cursor: pointer;
        }
        .staff-comment {
            border-top: 5px solid rgba(gray, 0.25);
            padding: 1rem 0;
            h3 {
                
            }
        }
        .job-update-at {
            margin: 1rem 0;
            border-top: 5px solid rgba(gray, 0.25);
            @include breakpoint(small) {
                font-size: 85%;
                margin: 0.5rem 0 0.5rem;
            }
            @include breakpoint(medium) {
                font-size: 90%;
                margin: 1rem 0;
            }
            @include breakpoint(large) {
                font-size: 90%;
                margin: 1rem 0;
            }
        }
        .job-info {
            border-color: $theme-color;
            text-align: center;
            word-break: break-all;
        }
        .table-header {
            background: $theme-color;
            color: $white;
            font-size: 18px;
            font-weight: 600;
        }
        .table-border {
            @include breakpoint(small) {
                border-bottom: 3px solid #f1f1f1;
            }
            @include breakpoint(medium) {
                border: 0;
            }
            @include breakpoint(large) {
                border: 0;
            }

        }

        .table-button {
            margin-top: 0.6rem;
            @include breakpoint(small) {
                width: 100%;
                max-width: 100%;
            }
            @include breakpoint(medium) {
                width: 100%;
                max-width: 230px;
            }
            @include breakpoint(large) {
                width: 100%;
                max-width: 230px;
            }

        }
        .company-info {
            //background: rgba($primary-color, 0.8);
            background: $theme-color;
            cursor: pointer;
            //background: $natural;
            margin: 0 0 1rem 0;
            padding: 0.7rem;
            text-align: center;
            border: 3px $theme-color solid;
            a {
                width: 100%;
                //background: $primary-color;
                //background: rgba($primary-color, 0.8);
                //background: $theme-color;
                color: $white;
                //color: $warning-color;
                font-size: 96%;
                font-weight: 600;
                &:hover, :focus {
                    background: $theme-color;
                }
            }
        }
        .job-button {
            margin-top: 0.6rem;
            @include breakpoint(small) {
                width: 100%;
                max-width: 100%;
            }
            @include breakpoint(medium) {
                width: 100%;
                max-width: 230px;
            }
            @include breakpoint(large) {
                width: 100%;
                max-width: 230px;
            }

        }
        .job-info-containder {
            margin: 1rem 0;
        
        }
        .tabs {
            border: 0;
            .is-active {
                color: $white; 
                a {
                    color: $white; 
                }
            }

            a {
                border:0;
                font-weight: bold;
                //color: $white;
                color: $black;
                @include breakpoint(small) {
                    font-size: 80%;
                }
                @include breakpoint(medium) {
                    font-size: 90%;
                }
                @include breakpoint(large) {
                    font-size: 100%;
                }

                &:hover {
                   color: $white; 
                }
                &:focus {
                   color: $white; 
                }
            }
            li {
                //margin-right: 0.5rem;
                border-right: 1px solid $white;
                //border-left: 1px solid $white;
                //border-top: 1px solid $primary;
                //border: 1px solid $primary;
                //border-radius: 0.3rem;
                width: 50%;
                text-align: center;
            
            }

        }
        .tabs-content {
            //border:0;
            //border: 3px solid rgba($warning-color, 0.75);
            //border: 5px solid rgba($primary, 0.75);
            margin: 0 0 1rem 0;
        }
        .tabs-content, .tabs-panel {
            @include breakpoint(small) {
                padding:0.1rem;
            }
            @include breakpoint(medium) {
                padding:0.5rem;
            }
            @include breakpoint(large) {
                padding:0.5rem;
            }
            table {
                //border: 4px solid $white;
                margin:0 0 2rem 0;
            }
        
        }
        .tabs-content-header {
            //border-bottom: 3px solid $primary;
            margin: 0 0 1rem;
            h3 {
                //font-weight: bold;
            }
            
        
        }
    }
    .entry-button {
        //padding: 1rem;
        //font-size: 2
    
    }
    .jobs-serach-panel-wrap {
        margin: 0 0 1rem;

    }
    .jobs-pagination {
        border-bottom: 4px rgba(gray, 0.25) dotted;
        margin: 1rem 0 0;
        font-size: 16px;
        padding: 0 0 0.35rem 0;
        @include global-link();
    }

    .jobs-search {
        background: $natural;
        margin: 0;
        //width: 100%;
        //max-width: 100%;
        .job-search-header {
            background: $theme-color;
            //background: scale-color($primary, $lightness: 1%);
            color: $white;
            //margin-bottom: 1.5rem;
            margin: 0rem 0 1.5rem;
            padding: 0.1rem 1rem;
            h1 {
                margin:0;
                padding:0;
                line-height: 2.5;
                //font-weight: 700;
            }
        }
        .job-search-panel {
            padding: 0 1rem;
            
        }
        label {}
        legend {
            background: $natural;
        }
        p {
            padding-top: 1rem;
        }
        .search-icon label {
            display: inline-block;
            white-space: nowrap;
        }
    }
    .jobs-count {
        padding: 1rem 1rem;
        background: $theme-color;
        color: $white;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }

    .entry {
        .form-header {
            border-bottom: 5px solid rgba(gray, 0.25);
            margin: 0.5rem 0 1.5rem 0;
            h4 {
                font-size: 18px;
                font-weight: bold;
            }
            
        }
        .form-confirm-title-header {
            border-bottom: 5px solid rgba(gray, 0.25);
            margin: 0.5rem 0;
            padding: 0.5rem;
            h4 {
                font-size: 18px;
                font-weight: bold;
                margin: 0;
            }
        }
        .form-confirm-header {
            //border-bottom: 5px solid rgba(gray, 0.25);
            margin: 0;
            padding: 0.5rem;
            h4 {
                font-size: 18px;
                font-weight: bold;
                margin: 0;
            }
            background: rgba(gray, 0.25);
            
            
        }
        .entry-confirm-details {
            @include breakpoint(small) {
                padding:0.25rem;
                margin: 0;
            }
            @include breakpoint(medium) {
                padding:0.5rem;
                margin: 0;
            }
            @include breakpoint(large) {
                padding:0.5rem;
                margin: 0;
            }
            hr {
                margin: 0;
                border: none;
                border-bottom: 3px dotted rgba(gray, 0.25);


            }
           .middle {
                @include breakpoint(small) {
                    margin: 0.1rem 0;
                    font-size: 13px;
                }
                @include breakpoint(medium) {
                    margin: 0.25;
                    font-size: 14px;
                }
                @include breakpoint(large) {
                    margin: 0.75rem 0;
                    font-size: 14px;
                }
            }

            .entry-confirm-details-container {
                border-bottom: 3px dotted rgba(gray, 0.25);
            
            }
            .career {
                .career-header {
                    margin: 0;
                    padding: 0;
                }
            }


        }

        .destro {
            margin: 0;
            padding: 0;
            a {
                font-size: 87%;
                border-bottom: 1px dotted rgba(gray, 0.25);
                color: rgba($black, 0.95); 
                //color: $black; 
                &:hover {
                    color: rgba($black, 0.75); 
                }
                &:focus {
                    color: $black;
               }
            }
        }

        @include breakpoint(small) {
            .entry-header {
                padding: 1rem;
                margin:1rem 0;
                color: $white;
                background: $theme-color;
                //background: $primary-color;
                h1 {
                    margin:0;
                    font-weight: 700;
                }
            }
            .thanks-header {
                padding: 1rem;
                margin:1rem 0;
                color: $white;
                background: $theme-color;
                //background: #c6538c;
            }

            .middle {
                @include form-label-middle();
                margin-top:0;
                margin-bottom:0;
            }
        }

        @include breakpoint(medium) {
            .middle {
                @include form-label-middle();
                padding: 0.5rem 0;
                margin:0;
            }
        }
        @include breakpoint(large) {
            .middle {
                @include form-label-middle();
            }
        }
        .entry-checkbox {
            label {
                display: inline;
                word-break: break-all;
            }
        }
        input[type='checkbox'] {
            margin: 0 0 0.8rem 0;
        
        }
    }
    .load-data-form {
        margin: 0 0 2.5rem 0;
        background: $natural;
        padding: 1rem;
        p {
            margin:0;
        }
        ul {
            font-size: 87%;
        }
        .load-panel {
            margin-top: 1rem;
            background: $white;
            //background: scale-color($theme-color, $lightness: 94%);
        }
    }

    .career {
        .career-header {
            margin: 1rem 0 0;
            padding: 1rem 0 0.2rem;
            //border-top: 1px solid rgba(gray, 0.25);
        }
    }
    .side-nav {
        .side-nav-container {
            //padding: 0.25rem 0 0.5rem 0;
            border-bottom: 1px solid rgba(gray, 0.15);
            margin-bottom: 0.75rem;
        }
        .side-nav-header {
            padding: 0.75rem;
            border-left: 4px solid rgba(gray, 0.25);
            background: $theme-color;
            h4 {
                margin:0;
                color: $white;
                font-weight: bold;
            }

        
        }
        .side-nav-contents {
            padding: 0.5rem 0.5rem; 
        }
        .pickup-tags {
            margin: 0.2rem;
            float: left;
            //background: $natural;
            @include global-link();
            
        
        }
    }

    .contact-form {
        //margin: 0 15rem;
    
    }
    
    .faq {  
        .faq-header {
            margin:0.5rem 0;
            border-left: 7px solid rgba(gray, 0.25);
            padding: 0.3rem 0.6rem;
            h2 {
                margin:0;
            }

        }
        .faq-contents {
            margin: 1rem 0;
            .faq-question {
                padding: 0.5rem;
            }
            .faq-answer {
                padding: 0 1.5rem 1.5rem 1.5rem;
                border-bottom: 1px solid rgba(gray, 0.25);
            }
            li {
                &:last-child {
                    border: 0;
                }
            
            }
        }
    }
    .information {
        .information-container {
            margin: 0 0 3rem 0;
            li {
                line-height: 1.7;
            }

        }
        .information-visual {
            margin:1rem 0;
        }
        .information-panel {
            background-color: $primary;
            color: $white;
        }
        .label {
            margin:0;
            font-size: 1rem;
            line-height: 1;
            //padding: 0.2rem;
        }
        .flow {
            margin: 1rem;
        }
        .information-nav {
            margin: 1rem 0;
            ul {
                li {
                    padding: 0.1rem;
                }
            }
            ol {
                padding:0;
                li {
                    list-style-position: inside;
                    padding: 0.25rem;
                    //border-top: 1px solid rgba(gray, 0.25);
                    &:last-child {
                        //border-bottom: 1px solid rgba(gray, 0.25);
                    
                    }
                
                }
            }
        }
    }
    .tags-search {
        margin-top: 2rem;
        .tags-search-contents {
            margin: 0 0 2.5rem 0;
        }

        .tags-search-container {
            .pickup-tags {
                margin: 0.3rem;
                padding: 0.3rem;
                float: left;
                @include global-link();
                .label {
                    padding: 0.8rem;
                }
            }
        }
        .button {
            margin:0;
            padding: 0.7rem;
        }
        .tags-search-title-header {
            padding: 0.75rem;
            //border-left: 4px solid rgba(gray, 0.25);
            margin: 0 0 1rem 0;
            background: $theme-color;
            h4 {
                color: $white;
                margin:0;
                font-weight: bold;
            }
        
        }
        .tag-search-sub-header {
            padding: 0 0.5rem 0.5rem;
            border-bottom: 3px solid rgba(gray, 0.25);
            margin: 1rem 0.5rem 0.5rem;
            h5 {
                margin:0;
                font-weight: bold;
            }
        
        
        }
        .side-nav-container {
            border:0;
        }

    }

}


.kiyaku-modal {
    font-size: 80%;
}

.admin-top-bar {
    background-color: #1564b3;
    background-image: linear-gradient(to right, #1a2b80 30%, #1a2b80);
    z-index: 99;
    padding: 1rem 0;
    .top-bar-site-logo {
        margin-top: 0.1rem;
        @include breakpoint(small) {
            width: 79%;
            max-width: 200px;
        }
        @include breakpoint(medium) {
            width: 200px;
        }
        @include breakpoint(large) {
            width: 200px;
        }
    }
    .top-bar-site-name {
        margin-left: 10px;
        width: 200px;
        color: $white;
        font-weight: bold;
        padding-bottom:10px;
    }
    .top-bar-nav-ico {
        margin: 5px 10px 5px 10px;
        width: 30px;
        .menu-icon {
            @include hamburger($color: $titlebar-icon-color, $color-hover: $titlebar-icon-color-hover, $width: $menu-icon-width, $height: 22px, $weight: 3px );
        }

    }
    .top-bar-nav-name {
        margin: 10px 10px 0 5px;
        font-weight: bold;
        font-size: 78%;
        //max-width: 20px;
        color: $white;
    }
    .top-bar-caption {
        margin: 0px 10px 0 5px;
        letter-spacing: 2px;
        //max-width: 20px;
        font-size: 80%;
        font-weight: 500;
        color: $white;
    }

    .search-icon {
        font-size: 24px;
        color: $white;
        margin: 0.45rem 0.6rem;
        label {
            white-space: nowrap;
            display: inline-block;
        }
    
    }
    address {
        margin-right: 1rem;
        color: $white;
        font-size: 80%;
        p {
            margin:0;
        }
        a {
        color: $white;
        
        }
    
    }
}

